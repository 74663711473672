exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lottery-tsx": () => import("./../../../src/pages/lottery.tsx" /* webpackChunkName: "component---src-pages-lottery-tsx" */),
  "component---src-pages-nagrania-tsx": () => import("./../../../src/pages/nagrania.tsx" /* webpackChunkName: "component---src-pages-nagrania-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */),
  "component---src-pages-tykuixpnofoffrq-tsx": () => import("./../../../src/pages/tykuixpnofoffrq.tsx" /* webpackChunkName: "component---src-pages-tykuixpnofoffrq-tsx" */),
  "component---src-templates-event-details-event-details-tsx": () => import("./../../../src/templates/EventDetails/EventDetails.tsx" /* webpackChunkName: "component---src-templates-event-details-event-details-tsx" */)
}

